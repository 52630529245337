import AnimatedIcons from "./AnimatedIcons";
import Body from "./Body";
import Hamburger from "./Hamburger";
import Header from "./Header";
import Info from "./Info";
import MobileNav from "./MobileNav";
import NavSubmenu from "./NavSubmenu";

export default {
	AnimatedIcons,
	Info,
	Hamburger,
	Header,
	MobileNav,
	Body,
	NavSubmenu,
};
